import { makeTheme } from 'bootstrap-styled';
import logo from './assets/logo.png';
import favicon from './assets/favicon.png';

const customization = {
  '$gotu-nav-logo': logo,
  '$gotu-favicon': favicon,
};

const layout = {};

const overwrite = (theme: object = {}) =>
  makeTheme({
    ...layout,
    ...customization,
    ...theme,
  });

export default overwrite;
