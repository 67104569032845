import React from 'react';
import { hot } from 'react-hot-loader/root';
import useFavicon from 'react-use/lib/useFavicon';
import { BootstrapProvider } from '@bootstrap-styled/provider';
import { Container, Jumbotron, H1, P } from '@bootstrap-styled/v4';
import Navbar from './Navbar';
import Onboard from './Onboard';
import makeTheme from '../theme';
import useOnboardingConfig from '../hooks/useOnboardingConfig';
import Alternate from './Alternate';

const NotFound: React.FunctionComponent = () => {
  return (
    <Container>
      <div className="text-center">
        <Jumbotron className="m-sm-5 mt-5 p-3">
          <Container>
            <H1>Not found</H1>
            <P>We could not find your onboarding request</P>
          </Container>
        </Jumbotron>
      </div>
    </Container>
  );
};

const App: React.FunctionComponent = () => {
  const [state, error] = useOnboardingConfig(window.location.href);
  const theme = makeTheme((state && state.platform.theme) || {});

  useFavicon(theme['$gotu-favicon']);

  return (
    <BootstrapProvider theme={theme}>
      <Alternate showAlternate={error} alternate={<NotFound />}>
        {state && (
          <>
            <Navbar />
            <Onboard
              customer={state.customer}
              campaign={state.campaign}
              completeUrl={state.completeUrl}
            />
          </>
        )}
      </Alternate>
    </BootstrapProvider>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
