import React from 'react';
import { Button, Alert } from '@bootstrap-styled/v4';
import Card from './Card';

const FinishCard: React.FunctionComponent<any> = ({
  disabled,
  finishing,
  onFinish,
  error,
}: any) => {
  return (
    <Card
      disabled={disabled}
      header="3. Finish"
      body={
        <>
          Finish the Onboarding process
          {error && <Alert color="danger">{error}</Alert>}
        </>
      }
      button={
        <Button
          color="success"
          size="lg"
          disabled={disabled || finishing}
          onClick={onFinish}
        >
          {finishing ? 'Processing..' : 'Finish'}
        </Button>
      }
    />
  );
};

export default FinishCard;
