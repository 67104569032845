import React from 'react';
import { Jumbotron, Container, H1, P } from '@bootstrap-styled/v4';

const FinishedJumbotron: React.FunctionComponent = () => {
  return (
    <div className="text-center">
      <Jumbotron className="m-sm-5 mt-5">
        <Container>
          <H1>All done</H1>
          <P>
            Thank you, your campaign will continue it's progress towards
            publication.
          </P>
        </Container>
      </Jumbotron>
      <P className="text-muted mt-1">You can close this page.</P>
    </div>
  );
};

export default FinishedJumbotron;
