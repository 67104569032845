import React, { useState, useEffect, useCallback } from 'react';
import { Container } from '@bootstrap-styled/v4';
import { FacebookProvider } from 'react-facebook';
import {
  useLogin,
  useLoginStatus,
  useFacebookPages,
  useAccountInfo,
} from '../hooks/useFacebook';
import * as adapi from '../utils/adapi';
import AlreadyOnboardedJumbotron from './AlreadyOnboardedJumbotron';
import FinishedJumbotron from './FinishedJumbotron';
import FacebookConnectCard from './FacebookConnectCard';
import FacebookPageCard from './FacebookPageCard';
import FinishCard from './FinishCard';
import Alternate from './Alternate';

const FACEBOOK_SDK_APP_ID = '590875117612218';
const FACEBOOK_LOGIN_SCOPE = 'business_management,pages_show_list';
const FACEBOOK_USER_FIELDS = 'name';

const Onboard: React.FunctionComponent<any> = ({
  campaign,
  customer,
  completeUrl,
}: any) => {
  const { loading: loginStatusLoading, userId, accessToken } = useLoginStatus();
  const [handleLogin, handleLogout] = useLogin(FACEBOOK_LOGIN_SCOPE);
  const [pages, pagesLoading] = useFacebookPages(userId);
  const [account, accountLoading] = useAccountInfo(
    userId,
    FACEBOOK_USER_FIELDS,
  );
  const [selectedPage, setSelectedPage] = useState(null);
  const [finishing, setFinishing] = useState(false);
  const [finished, setFinished] = useState(false);
  const [finishError, setFinishError] = useState<any>(null);

  useEffect(() => {
    setSelectedPage(null);
  }, [pages]);

  const onFinish = useCallback(async () => {
    setFinishError(null);
    setFinishing(true);
    try {
      const res = await adapi.post<any>(completeUrl, {
        page_id: selectedPage,
        user_id: userId,
        access_token: accessToken,
      });

      if (res.errors) {
        throw new Error(res.errors[0]);
      }
      setFinished(true);
    } catch (e) {
      setFinishError(adapi.getErrorString(e));
      setFinished(false);
    }
    setFinishing(false);
  }, [
    accessToken,
    completeUrl,
    selectedPage,
    userId,
    setFinishError,
    setFinished,
    setFinishing,
  ]);

  return (
    <Container>
      <Alternate
        showAlternate={customer && customer.onboarded_at}
        alternate={<AlreadyOnboardedJumbotron />}
      >
        <Alternate showAlternate={finished} alternate={<FinishedJumbotron />}>
          <>
            <FacebookConnectCard
              login={handleLogin}
              logout={handleLogout}
              statusLoading={loginStatusLoading}
              accountLoading={accountLoading}
              account={account}
              disabled={finishing}
            />

            <FacebookPageCard
              onPageChange={setSelectedPage}
              pages={pages}
              disabled={
                pagesLoading || loginStatusLoading || !account || finishing
              }
            />

            <FinishCard
              disabled={!accessToken || !account || !selectedPage}
              finishing={finishing}
              onFinish={onFinish}
              error={finishError}
            />
          </>
        </Alternate>
      </Alternate>
    </Container>
  );
};

const WrappedOnboard: React.FunctionComponent<any> = (props: any) => {
  return (
    <FacebookProvider appId={FACEBOOK_SDK_APP_ID}>
      <Onboard {...props} />
    </FacebookProvider>
  );
};

export default WrappedOnboard;
