import { useState, useEffect, useDebugValue } from 'react';
import { useApi } from 'react-facebook';

// These hooks can only be used if wrapped in react-facebook FacebookProvider

export function useLoginStatus(): {
  loading: boolean;
  connected: boolean;
  userId: string | null;
  accessToken: string | null;
} {
  const [, prepareApi] = useApi();
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setup = async () => {
      const api = await prepareApi();

      setResponse(await api.getLoginStatus());
      setLoading(false);
      api.subscribe('auth.statusChange', setResponse);
    };
    setup();

    return () => {
      const cleanup = async () => {
        const api = await prepareApi();
        api.unsubscribe('auth.statusChange', setResponse);
      };
      cleanup();
    };
  }, [prepareApi]);

  const connected = !loading && response && response.status === 'connected';
  const userId = (connected && response.authResponse.userID) || null;
  const accessToken = (connected && response.authResponse.accessToken) || null;

  useDebugValue(
    loading
      ? 'Loading'
      : connected
      ? `Connected as ${userId}`
      : 'Not connected',
  );

  return {
    loading,
    connected,
    userId,
    accessToken,
  };
}

export function useLogin(scope: string): [() => {}, () => {}] {
  const [, prepareApi] = useApi();

  const login = async () => {
    const api = await prepareApi();
    return api.login({
      scope,
    });
  };

  const logout = async () => {
    const api = await prepareApi();
    return api.logout();
  };

  return [login, logout];
}

export function useFacebookPages(userId: string | null): [any[], boolean] {
  const [, prepareApi] = useApi();
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getpages = async () => {
      setLoading(true);
      if (userId) {
        const api = await prepareApi();
        const response = await api.api('/me/accounts?fields=name,id,picture');
        setPages(response.data ? response.data : []);
      } else {
        setPages([]);
      }
      setLoading(false);
    };
    getpages();
  }, [userId, prepareApi]);

  useDebugValue(loading ? 'Loading' : `Got ${pages.length} pages`);

  return [pages, loading];
}

export function useAccountInfo(
  userId: string | null,
  fields: string,
): [any, boolean] {
  const [, prepareApi] = useApi();
  const [account, setAccount] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getaccount = async () => {
      setLoading(true);
      if (userId) {
        const api = await prepareApi();
        const response = await api.api(`/me?fields=${fields}`);
        setAccount(response);
      } else {
        setAccount(null);
      }
      setLoading(false);
    };
    getaccount();
  }, [userId, fields, prepareApi, setAccount, setLoading]);

  useDebugValue(
    loading ? 'Loading' : account ? `Name: ${account.name}` : 'Not account',
  );

  return [account, loading];
}
