import React from 'react';
import FacebookButton from './FacebookButton';
import Card from './Card';

const FacebookConnectCard: React.FunctionComponent<any> = ({
  disabled,
  login,
  logout,
  statusLoading,
  accountLoading,
  account,
}: any) => {
  const loading = statusLoading || accountLoading;
  const connected = !loading && account;

  return (
    <Card
      disabled={disabled}
      header="1. Connect with Facebook"
      body={
        loading
          ? 'Loading...'
          : connected
          ? `You are connected as ${account.name}`
          : 'Please connect with your Facebook account'
      }
      button={
        connected ? (
          <FacebookButton size="sm" onClick={logout}>
            Log out
          </FacebookButton>
        ) : (
          <FacebookButton size="sm" onClick={login}>
            Log in with Facebook
          </FacebookButton>
        )
      }
    />
  );
};

export default FacebookConnectCard;
