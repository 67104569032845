import axios, { AxiosResponse, AxiosError } from 'axios';

export type Iso8601String = string;

type AdapiResponse<T> = {
  result?: T;

  errors?: string[];

  meta: {
    status_code: number;
    requested_at: Iso8601String;
    completed_at: Iso8601String;
    method: string;
    endpoint: string;
    request_id: string;
  };
};

function isAdapiResponse<T>(item: any): item is AdapiResponse<T> {
  const maybeResponse = item as AdapiResponse<T>;
  return !!maybeResponse.meta && !!maybeResponse.meta.request_id;
}

function isAxiosError<T>(item: any): item is AxiosError<T> {
  return (item as AxiosError).isAxiosError;
}

export async function get<T>(url: string): Promise<AdapiResponse<T>> {
  const response: AxiosResponse<AdapiResponse<T>> = await axios.get(url);
  return response.data;
}

export async function post<T>(
  url: string,
  data: any,
): Promise<AdapiResponse<T>> {
  const response: AxiosResponse<AdapiResponse<T>> = await axios.post(url, data);
  return response.data;
}

export function getErrorString(item: any): string {
  if (isAxiosError<any>(item)) {
    if (item.response) {
      if (isAdapiResponse<any>(item.response.data)) {
        if (item.response.data.errors) {
          return item.response.data.errors[0];
        }
      }
    }

    return item.message;
  }

  return String(item);
}
