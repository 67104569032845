import React, { useState, useCallback } from 'react';
import Select from 'react-select';

interface FacebookPageSelectOption {
  id: string;
  name: string;
  picture: string;
}

interface Props {
  /** Pages that can be selected */
  pages: FacebookPageSelectOption[];

  /** Called when selected page changes */
  onChange: (selected: string | null) => void;

  /** Disable this select */
  disabled?: boolean;
}

const styles = {
  option: (styles: any, { data }: { data: FacebookPageSelectOption }) => ({
    ...styles,
    backgroundImage: `url(${data.picture})`,
    backgroundPosition: 'left 5px center',
    backgroundSize: '25px 25px',
    backgroundRepeat: 'no-repeat',
    paddingLeft: '40px',
  }),
  singleValue: (styles: any, { data }: { data: FacebookPageSelectOption }) => ({
    ...styles,
    backgroundImage: `url(${data.picture})`,
    backgroundPosition: 'left 5px center',
    backgroundSize: '25px 25px',
    backgroundRepeat: 'no-repeat',
    paddingLeft: '40px',
    height: '25px',
    lineHeight: '25px',
  }),
};

const FacebookPageSelect: React.FunctionComponent<Props> = ({
  pages,
  onChange,
  disabled = false,
}: Props) => {
  const [selected, setSelected] = useState();

  const options = pages.map(p => ({ value: p.id, label: p.name, ...p }));

  const handleChange = useCallback(
    (newSelected: any) => {
      setSelected(newSelected);
      onChange(newSelected ? newSelected.value : null);
    },
    [setSelected, onChange],
  );

  return (
    <Select
      value={selected}
      onChange={handleChange}
      options={options}
      styles={styles}
      isSearchable={false}
      isDisabled={disabled}
    />
  );
};

export default FacebookPageSelect;
