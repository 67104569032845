import React from 'react';
import { Button } from '@bootstrap-styled/v4';

const theme = {
  '$btn-primary-color': 'white',
  '$btn-primary-bg': '#3b5998',
  '$btn-border-width': '0',
  '$btn-border-radius': '4px',
  '$btn-border-radius-lg': '4px',
  '$btn-border-radius-sm': '4px',
};

type Props = any;

const FacebookButton: React.FunctionComponent<Props> = (props: Props) => {
  return <Button {...props} theme={theme}></Button>;
};

export default FacebookButton;
