import React from 'react';
import FacebookPageSelect from './FacebookPageSelect';
import Card from './Card';
import Alternate from './Alternate';
import { Alert } from '@bootstrap-styled/v4';

const FacebookPageCard: React.FunctionComponent<any> = ({
  disabled,
  pages,
  onPageChange,
}: any) => {
  const localPages = pages.map((p: any) => ({
    ...p,
    picture: p.picture.data.url,
  }));

  return (
    <Card
      disabled={disabled}
      header="2. Select your Facebook page"
      body={
        <Alternate
          showAlternate={!(disabled || localPages)}
          alternate={
            <Alert color="danger">You do not have any Facebook pages</Alert>
          }
        >
          <>
            Please select the page you will use for this campaign
            <div className="mt-2">
              <FacebookPageSelect
                onChange={onPageChange}
                pages={localPages}
                disabled={disabled}
              />
            </div>
          </>
        </Alternate>
      }
    />
  );
};

export default FacebookPageCard;
