import React from 'react';
import {
  Card as BootstrapCard,
  CardHeader,
  CardText,
  CardBlock,
} from '@bootstrap-styled/v4';
import styled from 'styled-components';

const DisabledCard = styled(BootstrapCard)`
  opacity: 0.4;
`;

interface Props {
  header: string;
  body: React.ReactNode | string;
  button?: React.ReactNode | null;
  disabled?: boolean;
}

const Card: React.FunctionComponent<Props> = ({
  header,
  body,
  button = null,
  disabled = false,
}: Props) => {
  const CardComponent = disabled ? DisabledCard : BootstrapCard;

  return (
    <CardComponent className="m1 mt-3 mt-sm-5">
      <CardHeader>{header}</CardHeader>
      <CardBlock>
        {body}
        <CardText className="text-center">{button}</CardText>
      </CardBlock>
    </CardComponent>
  );
};

export default Card;
