import React from 'react';
import styled from 'styled-components';
import { Navbar, NavbarBrand } from '@bootstrap-styled/v4';

const Nav = styled(Navbar)`
  height: 60px;
`;

const NavBrand = styled(NavbarBrand)`
  height: 50px;
  background-image: url(${(props: any) => props.theme['$gotu-nav-logo']});
  background-position-x: 5px;
  background-size: contain;
  background-repeat: no-repeat;
`;

export default function WrappedNavbar() {
  return (
    <Nav color="primary" full={true}>
      <NavBrand />
    </Nav>
  );
}
