import { useState, useEffect } from 'react';
import * as adapi from '../utils/adapi';

type AdapiOnboardingConfig = {
  customer: {
    name: string;
    language: string;
    onboarded_at: string;
  };
  campaign: {
    code: string;
    name: string;
  };
  platform: {
    theme: object;
    translations: object;
  };
};

export default function useOnboardingConfig(url: string) {
  const [state, setState] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const setup = async () => {
      try {
        const path = new URL(url).pathname.replace(/^\//, '');
        const encoded = path.replace('-', '+').replace('_', '/');
        const decoded = atob(encoded);
        const parsed = JSON.parse(decoded);

        const res = await adapi.get<AdapiOnboardingConfig>(
          `${parsed.api}onboarding/${parsed.key}/config`,
        );

        if (res.errors) {
          throw new Error(res.errors[0]);
        }

        const state: any = res.result;

        state.completeUrl = `${parsed.api}onboarding/${parsed.key}/onboard`;

        setState(state);
        setError(null);
      } catch (e) {
        setState(null);
        setError(e);
      }
    };
    setup();

    return () => {
      setState(null);
      setError(null);
    };
  }, [url, setState, setError]);

  return [state, error];
}
