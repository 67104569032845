import React from 'react';

type InternalProps = {
  showAlternate: boolean;
  alternate: React.ReactNode;
};

type Props = React.PropsWithChildren<InternalProps>;

const Alternate: React.FunctionComponent<Props> = ({
  alternate,
  showAlternate,
  children,
}: Props) => {
  return <>{showAlternate ? alternate : children}</>;
};

export default Alternate;
